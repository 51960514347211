import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/index/About"
import Media from "../components/index/Media"
import { useStaticQuery, graphql } from "gatsby"
import Intro from "../components/index/Intro"

const IndexPage = () => (
  <Layout isHome>
    <SEO title="Home" />

    <div className="bg-white">
      <div className="max-w-6xl mx-auto px-4 lg:px-8 py-8 lg:py-12">
        <Intro />
      </div>
    </div>

    <div className="bg-primary/5">
      <div className="max-w-6xl mx-auto px-4 lg:px-8 py-8 lg:py-8 flex flex-col lg:flex-row gap-4 lg:gap-24 items-center">
        {/* Left */}
        <div className="w-1/2 lg:w-1/4">
          <div className="">
            {/* <StaticImage
              src="../images/antibody2.jpg"
              class="rounded-full opacity-[99%]"
            /> */}
          </div>
        </div>
        {/* Right */}
        <div className="flex-1">
          <div className="flex flex-col gap-4 text-center lg:text-right">
            <h3 className="text-2xl lg:text-3xl uppercase font-bold">
              ANTIBODY DRUG CONJUGATES
            </h3>
            <p>
              Based upon our patent protected platform we are developing the
              next generation of safer ADC payloads with a high therapeutic
              index.
            </p>
            <div>
              <div className="inline-flex  group flex-row gap-2 items-center text-secondary">
                <div className="relative group-hover:translate-x-3 transition-all cursor-pointer ease-in-out">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 144 145"
                    style={{ minWidth: 18, width: 18, marginRight: "1rem" }}
                  >
                    <path d="M140.189 72.596a8.803 8.803 0 00-2.619-6.266L78.183 6.943c-3.446-3.446-9.088-3.447-12.527-.007-3.446 3.446-3.44 9.08 0 12.52L109.93 63.73l-90.764.002c-4.873 0-8.86 3.987-8.86 8.86.001 4.872 3.988 8.859 8.86 8.86l90.758.004-44.27 44.27c-3.445 3.446-3.439 9.08.001 12.52 3.446 3.446 9.081 3.44 12.52.001L137.57 78.85a8.772 8.772 0 002.619-6.254z"></path>
                  </svg>
                </div>
                <div>
                  <Link to="/research-development/biopharma/">Visit </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-primary/20">
      <div className="max-w-6xl mx-auto px-4 lg:px-8 py-8 lg:py-8 flex flex-col lg:flex-row gap-4 lg:gap-24 items-center">
        {/* Left */}
        <div className="w-1/2 lg:w-1/4 lg:order-last">
          <div className="">
            {/* <StaticImage
              src="../images/omega3-2.png"
              class="rounded-full opacity-[99%]"
            /> */}
          </div>
        </div>
        {/* Right */}
        <div className="flex-1">
          <div className="flex flex-col gap-4 text-center lg:text-left">
            <h3 className="text-2xl lg:text-3xl uppercase font-bold">
              CYANOBACTERIAL TOXIN STANDARDS AND REAGENTS
            </h3>
            <p>
              Our range of high-quality standards and reagents are trusted by
              water authorities around the world.
            </p>
            <div>
              <div className="inline-flex  group flex-row gap-2 items-center text-secondary">
                <div className="relative group-hover:translate-x-3 transition-all cursor-pointer ease-in-out">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 144 145"
                    style={{ minWidth: 18, width: 18, marginRight: "1rem" }}
                  >
                    <path d="M140.189 72.596a8.803 8.803 0 00-2.619-6.266L78.183 6.943c-3.446-3.446-9.088-3.447-12.527-.007-3.446 3.446-3.44 9.08 0 12.52L109.93 63.73l-90.764.002c-4.873 0-8.86 3.987-8.86 8.86.001 4.872 3.988 8.859 8.86 8.86l90.758.004-44.27 44.27c-3.445 3.446-3.439 9.08.001 12.52 3.446 3.446 9.081 3.44 12.52.001L137.57 78.85a8.772 8.772 0 002.619-6.254z"></path>
                  </svg>
                </div>
                <div>
                  <Link to="/standards-reagents/cyanobacterial-standards/">
                    Visit{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

// <div className="max-w-3xl mx-auto wp-content">
//             <p>
//               Cyanobacteria and microalgae are incredibly diverse and contain a
//               wide variety of bioactive compounds that can be useful in various
//               industries. For example, some strains of cyanobacteria and
//               microalgae are rich in pigments such as chlorophyll,
//               phycobiliproteins, and carotenoids, which can be used as natural
//               food colorants, cosmetics, and inks. These pigments also have
//               antioxidant properties, which make them useful as dietary
//               supplements and in the prevention of certain diseases.
//             </p>

//             <p>
//               Other compounds found in cyanobacteria and microalgae include
//               polysaccharides, lipids, and proteins. These compounds have
//               potential applications in the food industry, where they can be
//               used as emulsifiers, thickeners, and stabilizers. They can also be
//               used to produce biofuels, bioplastics, and other sustainable
//               materials. Furthermore, cyanobacteria and microalgae have been
//               shown to produce a variety of bioactive compounds, such as
//               antibiotics, antivirals, and anticancer agents. These compounds
//               have the potential to be developed into new drugs and therapies.
//             </p>

//             <p>
//               Overall, the diverse range of compounds found in cyanobacteria and
//               microalgae make them valuable resources for various industries.
//               Ongoing research in this field continues to uncover new
//               applications and benefits of these microorganisms.
//             </p>

//             <p>
//               The vision of the Simris Group is to play the leading role in a
//               future microalgae-based industry producing products, and materials
//               for products, in a drastically more sustainable way than current
//               industry.
//             </p>

//             <p>
//               Within Simris Biologics we hold a library of over 1,200 strains of
//               cyanobacteria and over 5,000 novel compounds. Initial screening
//               has revealed compounds with potential application as pesticides,
//               herbicides, fungicides, plant growth stimulants, novel
//               antibiotics, novel toxins, antioxidants. Our research continues to
//               uncover the structure and function of these novel compounds and
//               will feed our development pipeline for years to come.
//             </p>
//           </div>
