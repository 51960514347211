import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function Intro() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 19 }) {
        id
        frontPageSections {
          introFrontpage {
            headlineIntro
            textIntro
          }
        }
      }
    }
  `)

  const intro = data.wpPage.frontPageSections.introFrontpage
  const { headlineIntro, textIntro } = intro

  return (
    <div className="flex flex-col lg:flex-row gap-4 lg:gap-8 items-center">
      {/* Left */}
      <div className="flex-1 flex flex-col gap-4 lg:gap-8 text-center">
        <h3 className="text-2xl lg:text-3xl uppercase font-bold max-w-3xl mx-auto">
          {headlineIntro}
        </h3>

        <div className="flex flex-col gap-4 text-xl max-w-3xl mx-auto text-left">
          <div
            dangerouslySetInnerHTML={{ __html: textIntro }}
            className="wp-content"
          />
        </div>
      </div>
      {/* Right */}
    </div>
  )
}
